<template>
  <div class="StatusPageOnboarding">
    <img class="image"
         :src="require('@/assets/images/monkey.png')" />

    <div class="intro">
      <div class="title">
        Status Pages
      </div>

      <template v-if="user.monitors_number === 0">
        <div class="description">
          You don't have any monitors. You need to create at least one monitor to show on the Status Page.
        </div>
        <base-button class="cta-button"
                     color="primary"
                     @click="goToMonitorList">Create a Monitor -></base-button>
      </template>

      <template v-if="user.monitors_number > 0">
        <div class="description">
          Status pages are used for displaying status of your sites to your client or your team.
        </div>
        <base-button class="cta-button"
                     color="primary"
                     @click="goToNewStatusPage">Add Status Page</base-button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToMonitorList () {
      this.$router.push({
        name: 'monitors.list'
      })
    },

    goToNewStatusPage () {
      this.$router.push({
        name: 'statusPages.new'
      })
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .StatusPageOnboarding {
    @include flex-center;

    flex-direction: column;
    //flex: 1 0;
    //padding-bottom: 200px;

    .image {
      width: 54px;
      opacity: 1;
      margin: 40px 0 15px;
      animation-delay: 0.2s;
    }

    .intro {
      max-width: 600px;
      margin-bottom: 20px;
      text-align: center;
      color: #555;

      animation-delay: 0.4s;

      .title {
        margin-bottom: 10px;
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -.024em;
      }

      .description {
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 20px;
      }
    }

    .cta-button {
      //width: 200px;
      height: 40px;
      margin: 0 auto;
      font-size: 16px;
    }
  }
</style>
