<template>
  <div class="StatusPagesList">
    <status-page-onboarding v-if="user.status_pages_number === 0" />

    <template v-if="user.status_pages_number > 0">
      <div class="top">
        <div class="page-header">
          Status Pages
        </div>
        <div class="right" v-if="user.role !== role.Viewer && user.role !== role.Accountant">
          <base-button color="primary"
                       ref="newStatusPageButton"
                       class="addNew"
                       @click="goToNewStatusPage"
                       :icon="isMobile ? 'IconAdd' : null"
          >
            {{ !isMobile ? 'New Status Page' : '' }}
          </base-button>
        </div>
      </div>

      <status-page-list />

      <div v-show="false">
        <hint-limit-exceeded ref="limitExceeded" />
      </div>
    </template>
  </div>
</template>

<script>
import StatusPageList from '@/components/StatusPageList/StatusPageList.vue'
import StatusPageOnboarding from '@/components/StatusPageList/StatusPageOnboarding.vue'
import HintLimitExceeded from '@/components/Hints/HintStatusPagesLimitExceeded.vue'
import { Role } from '@/_helpers/role.js'

import tippy from 'tippy.js'

export default {
  components: {
    StatusPageList,
    StatusPageOnboarding,
    HintLimitExceeded
  },

  metaInfo: {
    title: 'Status Pages'
  },

  data () {
    return {
      tippy: null,
      role: Role
    }
  },

  methods: {
    goToNewStatusPage () {
      if (this.user.exceeded_status_pages_limit) {
        this.showTippy()

        return false
      }

      this.$router.push({
        name: 'statusPages.new'
      })
    },

    showTippy () {
      if (this.tippy) {
        this.tippy.destroy()
      }

      this.tippy = tippy(this.$refs.newStatusPageButton.$el, {
        allowHTML: true,
        content: this.$refs.limitExceeded.$el,
        interactive: true,
        arrow: false,
        placement: 'bottom-end',
        trigger: 'manual',
        theme: 'big-padding',
        animation: 'shift-away-extreme'
      })

      this.tippy.show()
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),
    isMobile () {
      return window.innerWidth < 767
    }
  }
}
</script>

<style lang="scss" scoped>
  .StatusPagesList {
    display: flex;
    flex-direction: column;
    flex: 1 0;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .addNew {
      height: 40px;
      ::v-deep {
        svg {
          margin-right: 0 !important;
        }
      }
    }
  }
</style>
