<template>
  <div class="HintStatusPagesNumberExceeded">
    <p class="title mb-2">You have exceeded the status pages number</p>
    <p>
      You’ve reached the limit of {{ user.subscription_plan.status_pages }} status pages. To add more, consider upgrading your plan.
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .HintStatusPagesNumberExceeded {
    .title {
      font-weight: bold;
    }

    .link {
      color: #fff;
      font-weight: 600;
      text-decoration: underline;
    }
  }
</style>
