<template>
  <div class="ItemPlaceholderList">
    <item-placeholder v-for="placeholder in placeholders"
                      :key="placeholder.id" />
  </div>
</template>

<script>
import ItemPlaceholder from './ItemPlaceholder.vue'

export default {
  components: {
    ItemPlaceholder
  },

  props: {
    numberOfPlaceholders: {
      required: false,
      type: Number,
      default: 3
    }
  },

  data () {
    return {
      placeholders: []
    }
  },

  created () {
    for (let i = 0; i < this.numberOfPlaceholders; i++) {
      this.placeholders.push({ id: i })
    }
  }
}
</script>
